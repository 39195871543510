









































import { Vue, Component } from "vue-property-decorator";
import { findSysLinkByUserId, getUserByHeader,getServerInfo } from "@/api/global";
import { UserModule } from "@/store/modules/user";
import { getHostName } from "@/utils/common";
@Component({
  name: "EnterPage"
})
export default class EnterPage extends Vue {
  list: object[] = [];
  userName = "";
  serverVersion='v2.0';
  apiVersion='v1.0';
  days=0;
  filterList = [
    {
      name: "总体态势",
      icon: require("@/assets/images/enter/img_list_1@2x.png"),
      png: require("@/assets/images/enter/list_1.png")
    },
    {
      name: "综合业务",
      icon: require("@/assets/images/enter/img_list_2@2x.png"),
      png: require("@/assets/images/enter/list_2.png")
    },
    {
      name: "后台管理",
      icon: require("@/assets/images/enter/img_list_3@2x.png"),
      png: require("@/assets/images/enter/list_3.png")
    },
    {
      name: "基础信息",
      icon: require("@/assets/images/enter/img_list_4@2x.png"),
      png: require("@/assets/images/enter/list_4.png")
    },
    {
      name: "访客管理",
      icon: require("@/assets/images/enter/img_list_5@2x.png"),
      png: require("@/assets/images/enter/list_5.png")
    },
    {
      name: "地图管理",
      icon: require("@/assets/images/enter/img_list_6@2x.png"),
      png: require("@/assets/images/enter/list_6.png")
    },
    {
      name: "决策分析",
      icon: require("@/assets/images/enter/img_list_7@2x.png"),
      png: require("@/assets/images/enter/list_7.png")
    },
    {
      name: "人像检索",
      icon: require("@/assets/images/enter/img_list_8@2x.png"),
      png: require("@/assets/images/enter/list_8.png")
    },
    {
      name: "门禁管理",
      icon: require("@/assets/images/enter/img_list_9@2x.png"),
      png: require("@/assets/images/enter/list_9.png")
    },
    {
      name: "车场管理",
      icon: require("@/assets/images/enter/img_list_10@2x.png"),
      png: require("@/assets/images/enter/list_10.png")
    },
    {
      name: "物业管理",
      icon: require("@/assets/images/enter/img_list_11@2x.png"),
      png: require("@/assets/images/enter/list_11.png")
    },
    {
      name: "缴费管理",
      icon: require("@/assets/images/enter/img_list_12@2x.png"),
      png: require("@/assets/images/enter/list_12.png")
    },
    {
      name: "视频管理",
      icon: require("@/assets/images/enter/img_list_13@2x.png"),
      png: require("@/assets/images/enter/list_13.png")
    },
    {
      name: "巡更管理",
      icon: require("@/assets/images/enter/img_list_14@2x.png"),
      png: require("@/assets/images/enter/list_14.png")
    },
    {
      name: "党建专题",
      icon: require("@/assets/images/enter/img_list15.png"),
      png: require("@/assets/images/enter/list_15.png")
    }
  ];

  defaultItem = {
    icon: require("@/assets/images/enter/img_list_default.png"),
    png: require("@/assets/images/enter/list_default.png")
  };

  get pageList() {
    const { list } = this;
    const result: object[][] = [];
    list.forEach((item, index) => {
      if (index % 8 === 0) {
        result.push([]);
      }
      result[result.length - 1].push(item);
    });
    return result;
  }

  created() {
    this.getData();
    this.getUserInfo();
    this.getServerInfo();
  }

  async getUserInfo() {
    const data = await getUserByHeader();
    if (data) {
      this.userName = data.userName;
    }
  }
 async getServerInfo() {
    const data = await getServerInfo();
    if (data) {
      this.serverVersion = data.serverVersion;
      this.apiVersion = data.apiVersion;
      this.days = data.days;
    }
  }
  async getData() {
    const data = await findSysLinkByUserId();
    if (data) {
      const { filterList, defaultItem } = this;
      const list: object[] = [];
      data.forEach(
        (item: {
          isValid: string;
          linkUrl: string;
          linkDescribe: string;
          linkName: string;
        }) => {
          const { isValid, linkUrl, linkDescribe, linkName } = item;
          if (isValid === "Y") {
            const result = filterList.find(({ name }: { name: string }) => {
              return name === linkName;
            });
            if (result) {
              list.push({
                name: linkName,
                describe: linkDescribe,
                url: linkUrl,
                icon: result.icon,
                gif: result.png
              });
            } else {
              list.push({
                name: linkName,
                describe: linkDescribe,
                url: linkUrl,
                icon: defaultItem.icon,
                gif: defaultItem.png
              });
            }
          }
        }
      );
      this.list = list;
    }
  }

  enter({ url }: { url: string }) {
    const str = "Webshell:";
    const str1 = "EasyPrint:";
    if (url.indexOf(str) !== -1 || url.indexOf(str1) !== -1) {
      window.location.href = url;
      return;
    }
    if (/^http/.test(url)) {
      // http页面则直接跳转
      window.open(url);
    } else {
      // 将多余的/去掉 后台转发路径后面需要加斜杠
      url = url.replace(/\//g, "");
      if (process.env.NODE_ENV === "development") {
        window.open(`${process.env.VUE_APP_API}/${url}/`);
      } else {
        // 如果是线上，则直接跳到公用登录页
        window.open(`${getHostName()}/${url}/`);
      }
    }
  }

  logout() {
    UserModule.confirmLogout();
  }
}
